import { createSSRApp, h } from 'vue'
import { createInertiaApp, Head, Link, router } from '@inertiajs/vue3'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import { ZiggyVue } from 'ziggy-js'
import AosVue from 'aos-vue'
import * as Sentry from '@sentry/vue'

import 'flowbite'
import '../css/app.css'

createInertiaApp({
    progress: {
        delay: 250,
        color: 'red',
        includeCSS: true,
        showSpinner: true
    },
    title: (title) => `${title}`,
    resolve: (name) =>
        resolvePageComponent(
            `./Pages/${name}.vue`,
            import.meta.glob('./Pages/**/*.vue')
        ),
    setup ({ el, App, props, plugin }) {
        const app = createSSRApp({ render: () => h(App, props) })
        Sentry.init({
            app,
            dsn: import.meta.env.SENTRY_LARAVEL_DSN,
            environment: import.meta.env.VITE_APP_ENV,
            integrations: [
                Sentry.browserTracingIntegration({ router }),
                Sentry.replayIntegration()
            ]
        })

        return app
            .use(AosVue)
            .component('Link', Link)
            .component('Head', Head)
            .use(ZiggyVue)
            .use(plugin)
            .mount(el)
    }
}
)

/**
* Track Page and Send to Google Analytic and HubSpot
* */
if (import.meta.env.VITE_APP_ENV === 'production') {
    router.on('navigate', (event) => {
        gtag('js', new Date())
        gtag('event', 'page_view', {
            page_location: event.detail.page.url
        })
        const _hsq = window._hsq = window._hsq || []
        _hsq.push(['setPath', event.detail.page.url])
        _hsq.push(['trackPageView'])
    })
}
